import * as React from 'react';
import {FC} from 'react';
import quickReleasePlaceholderImage from './assets/quick.webp';
import solidAxlePlaceholderImage from './assets/solid.webp';
import thruAxlePlaceholderImage from './assets/thru.webp';
import {styled} from '@mui/material/styles';
import {HitchRequest} from "../utils/types";
import {useRecordContext} from "react-admin";

const Img = styled('img')({
    height: '80px',
});

const HitchRequestDetailsHeader: FC = props => {

    const record: HitchRequest = useRecordContext(props);
    const thumbnail = {
        image: thruAxlePlaceholderImage,
        alt: "Hitch image"
    };

    if (!record) {
        return <Img src={thumbnail.image} alt={thumbnail.image}/>;
    }

    if (record.hitchType === 'Solid axle') {
        thumbnail.image = solidAxlePlaceholderImage;
    } else if (record?.hitchType === 'Quick release') {
        thumbnail.image = quickReleasePlaceholderImage;
    }

    return <Img src={thumbnail.image} alt={thumbnail.image}/>;
};

export default HitchRequestDetailsHeader;
