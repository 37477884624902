import * as React from 'react';
import {Card, CardHeader, List} from '@mui/material';
import {useTranslate} from 'react-admin';
import {Marketplace} from "./Marketplace";

const StateOfContentReport = (props) => {
    const {stats} = props;
    const translate = useTranslate();

    return (
        <Card sx={{flex: 1}}>
            <CardHeader
                title={translate('resources.reports.quickViewReports.stateOfContent.title')}
                subheader={translate('resources.reports.quickViewReports.stateOfContent.tip')}
            />
            <List dense={true}>
                {stats.data.map(marketplace => (
                    <Marketplace key={marketplace.id} marketplace={marketplace}/>
                ))}
            </List>
        </Card>
    );
};

export default StateOfContentReport;
