import * as React from 'react';
import {
    Datagrid,
    List,
    TextField,
    SearchInput,
    SimpleForm,
    required,
    TranslatableInputs,
    TextInput,
    useRedirect,
    DeleteWithConfirmButton,
    useRecordContext,
    FunctionField,
    usePermissions,
    SelectInput
} from 'react-admin';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import {Attribute} from "../types";
import {Link} from "@mui/material";
import {hasAccess} from "ra-auth-acl";
import {useMarketplaces} from "../utils/useMarketplaces";
import {MarketplaceChoice} from "../utils/types";

const AttributeCreateButton = () => {
    const redirect = useRedirect();

    const onSuccess = (data: Attribute) => {
        redirect(`/attributes/${data.id}`);
    };

    return (
        <CreateInDialogButton mutationOptions={{onSuccess}}>
            <SimpleForm>
                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                    <TextInput source="name_translatable" label="resources.products.show.attribute_name"
                               validate={[required()]}/>
                    <TextInput source="description_translatable" label="resources.products.show.attribute_desc"/>
                </TranslatableInputs>
            </SimpleForm>
        </CreateInDialogButton>
    );
};

const DeleteButton = () => {
    const record: Attribute = useRecordContext();

    if (record.isEditable) {
        return <DeleteWithConfirmButton
            redirect={false}
            mutationMode="optimistic"
            confirmTitle=" "
        />;
    }

    return null;
};

const AttributeList = () => {
    useDefineAppLocation('settings.settings_attributes');
    const {permissions} = usePermissions();
    const editable = hasAccess(permissions, 'attribute.edit');
    const {marketplaces} = useMarketplaces();

    const choices: MarketplaceChoice[] = marketplaces.filter(mp => !mp.isDefault && mp.sourceCode !== 'SL').map(marketplace => {
        return {
            id: marketplace.id,
            name: marketplace.name
        };
    });

    const defaultChoice: MarketplaceChoice = {
        id: 'default',
        name: 'resources.products.show.own_product_attributes'
    };

    choices.unshift(defaultChoice);

    const filters = [
        <SearchInput
            key="q"
            source="q"
            alwaysOn
            name="q"
            sx={{
                '& .MuiInputBase-root': {
                    height: 48,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0
                }
            }}
        />,
        <SelectInput
            source="defaultMarketplace"
            label="resources.products.show.product_attributes_mp_filter"
            alwaysOn
            choices={choices}
            name="defaultMarketplace"
            sx={{
                width: 300,
                '& .MuiInputBase-root': {
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0
                }
            }}
        />,
    ];

    return (
        <List
            filters={filters}
            actions={editable ? <AttributeCreateButton/> : false}
        >
            <Datagrid
                rowClick="edit"
                isRowSelectable={(record: Attribute) => editable && record.isEditable}
            >
                <TextField source="id"/>
                <TextField source="name" label={`resources.attributes.fields.name`}/>
                <TextField source="tip" label={`resources.attributes.fields.tip`}/>
                <FunctionField
                    source="defaultMarketplace.name"
                    name="defaultMarketplace"
                    label={`resources.attributes.fields.origin_marketplace`}
                    sortable={true}
                    render={(attr: Attribute) => {
                        return (attr?.defaultMarketplace ?
                            <Link href={`/#${attr.defaultMarketplace['@id']}`}>
                                {attr.defaultMarketplace.name}
                            </Link> : "n/a");
                    }}
                />
                <TextField source="valueType" label={`resources.attributes.fields.value_type`}/>
                {editable ? <DeleteButton/> : null}
            </Datagrid>
        </List>
    );
};

export default AttributeList;
