import * as React from 'react';
import {
    ListItem,
    ListItemText,
    Link as MuiLink,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
    useCreatePath,
    useRecordContext,
} from 'react-admin';
import { HitchRequest } from '../utils/types';

export const HitchRequestItem = () => {
    const record = useRecordContext<HitchRequest>();
    const createPath = useCreatePath();
    if (!record) {
        return null;
    }
    return (
        <MuiLink
            to={createPath({
                resource: 'hitch-requests',
                type: 'edit',
                id: record.id,
            })}
            component={Link}
            underline="none"
            color="inherit"
        >
            <ListItem>
                <ListItemText
                    primary={`${record.hitchType} (${record.firstMeasurement}${record.secondMeasurement ? `/${record.secondMeasurement}` : ''})`}
                    secondary={record.status}
                    secondaryTypographyProps={{ noWrap: true }}
                />
            </ListItem>
        </MuiLink>
    );
};
