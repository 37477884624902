import * as React from 'react';
import {
    EditBase,
    useTranslate,
    SimpleForm,
    DateField,
    EditProps,
    Labeled,
    TextField,
    EmailField,
    required, Toolbar, SelectInput, FunctionField
} from 'react-admin';
import {Box, Grid, Stack, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {AutoSave} from '@react-admin/ra-form-layout';
import HitchRequestDetailsHeader from './HitchRequestDetailsHeader';
import {MarkdownInput} from "@react-admin/ra-markdown";
import {HitchRequest} from "../utils/types";

interface Props extends EditProps<HitchRequest> {
    onCancel: () => void;
}

export const STATUSES = [
    {id: 'open', name: 'resources.reports.reports.hitchRequest.open'},
    {id: 'close', name: 'resources.reports.reports.hitchRequest.close'},
];

export const TYPES = [
    {id: 'Thru axle', name: 'Thru axle'},
    {id: 'Solid axle', name: 'Solid axle'},
    {id: 'Quick release', name: 'Quick release'}
];

const HitchRequestShow = ({onCancel, ...props}: Props) => {
    const translate = useTranslate();
    return (
        <EditBase {...props} mutationMode="pessimistic">
            <Box pt={5} width={{xs: '100vW', sm: 400}} mt={{xs: 2, sm: 1}}>
                <Stack direction="row" p={2}>
                    <Typography variant="h6" flex="1">
                        {translate('resources.back_in_stock.details_label')}
                    </Typography>
                    <IconButton onClick={onCancel} size="small">
                        <CloseIcon/>
                    </IconButton>
                </Stack>
                <SimpleForm sx={{pt: 0, pb: 0}} toolbar={<Toolbar><AutoSave debounce={1000}/></Toolbar>}>
                    <Grid container rowSpacing={1} mb={1}>
                        <Grid item xs={6}>
                            <HitchRequestDetailsHeader/>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <TextField
                                    source="hitchType"
                                    label={`resources.reports.reports.hitchRequest.hitch_type`}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <TextField
                                    source="hitchType"
                                    label={`resources.reports.reports.hitchRequest.hitch_type`}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <FunctionField
                                    source="status"
                                    name="status"
                                    label={`resources.reports.reports.hitchRequest.status`}
                                    sortable={true}
                                    render={(request: HitchRequest) => {
                                        return request.status === 'open' ? translate('resources.reports.reports.hitchRequest.open') : translate('resources.reports.reports.hitchRequest.close');
                                    }}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <TextField
                                    source="firstMeasurement"
                                    label={`resources.reports.reports.hitchRequest.first_measurement`}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <TextField
                                    label={`resources.reports.reports.hitchRequest.second_measurement`}
                                    emptyText={'n/a'}
                                    source="secondMeasuremrnt"
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <DateField source="createdAt" label={`resources.back_in_stock.requested_at`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <TextField label={`resources.reports.reports.hitchRequest.language`} source="language"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12}>
                            <Labeled>
                                <EmailField
                                    source="email"
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12}>
                            <Labeled>
                                <TextField
                                    source="phone"
                                    label={`resources.reports.reports.hitchRequest.phone`}
                                    emptyText={'n/a'}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectInput
                                label={`resources.reports.reports.hitchRequest.status`}
                                source="status"
                                fullWidth
                                name="status"
                                validate={[required()]}
                                choices={STATUSES}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MarkdownInput
                                height="200px"
                                source="internalNote"
                                name="internalNote"
                                label={`resources.reports.reports.hitchRequest.internalNote`}
                            />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Box>
        </EditBase>
    );
};

export default HitchRequestShow;
