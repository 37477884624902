import * as React from 'react';
import {
    CheckboxGroupInput,
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
    TranslatableInputs,
    ReferenceArrayInput,
    useRecordContext,
    TextField,
    useTranslate,
    TranslatableFields,
    FunctionField,
    Labeled,
    Datagrid,
    DeleteWithConfirmButton,
    List,
    FormDataConsumer,
    SimpleShowLayout,
    Toolbar, SaveButton, required, usePermissions, useDataProvider, AutocompleteArrayInput
} from 'react-admin';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {Attribute, Marketplace, ProductTag} from "../types";
import {Grid, Stack, Alert} from "@mui/material";
import {ShowInDialogButton, EditInDialogButton, CreateInDialogButton} from "@react-admin/ra-form-layout";
import {hasAccess} from "ra-auth-acl";
import {useEffect, useState} from "react";

const valueTypes = [
    {id: 'TEXT', name: 'Text'},
    {id: 'LONG_TEXT', name: 'Long text'},
    {id: 'INTEGER', name: 'Integer'},
    {id: 'MEASUREMENT', name: 'Measurement'},
    {id: 'MEDIA', name: 'Media'},
    {id: 'LIST', name: 'List'},
    {id: 'BOOLEAN', name: 'True/False'},
    {id: 'LIST_MULTIPLE_VALUES', name: 'List multiple values'}
];
const DefaultMarketplaceNote = (props: { marketplace: Marketplace }) => {
    const translate = useTranslate();

    return (
        <Alert severity="warning" sx={{marginBottom: '20px'}}>
            {translate('resources.attributes.edit.default_marketplace_note', {marketplace: props.marketplace.name})}
        </Alert>
    );
}
const CreateButton = (props: { attribute: Attribute }) => (
    <CreateInDialogButton
        record={{attribute: `attributes/${props.attribute.id}`}}
        title={`resources.attributes.edit.add_new_option`}
    >
        <SimpleForm>
            <TranslatableInputs
                locales={['de', 'en', 'fr']}
                fullWidth
                sx={{marginTop: 0}}
            >
                <TextInput source="value_translatable" validate={[required()]}
                           label={`resources.attributes.edit.value`}/>
            </TranslatableInputs>
        </SimpleForm>
    </CreateInDialogButton>
);

const Editable = (props: { record: Attribute }) => {
    const {permissions} = usePermissions();
    const editable = hasAccess(permissions, 'attribute.edit');
    const record: Attribute = props.record;
    const [valueType, setValueType] = useState(record.valueType);
    const [tags, setTags] = useState<ProductTag[]>([]);
    const dataProvider = useDataProvider();

    const tagGroups = [
        {id: 'kid_trailer', name: 'Kid + Trailer'},
        {id: 'dog_trailer', name: 'Dog + Trailer'},
        {id: 'cargo_trailer', name: 'Cargo + Trailer'},
        {id: 'bob_trailer', name: 'Bob + Trailer'},
        {id: 'kid_accessory', name: 'Kid + Accessory'},
        {id: 'dog_accessory', name: 'Dog + Accessory'},
        {id: 'cargo_accessory', name: 'Cargo + Accessory'},
        {id: 'bob_accessory', name: 'Bob + Accessory'},
        {id: 'kid_spare_part', name: 'Kid + Spare part'},
        {id: 'dog_spare_part', name: 'Dog + Spare part'},
        {id: 'cargo_spare_part', name: 'Cargo + Spare part'},
        {id: 'bob_spare_part', name: 'Bob + Spare part'}
    ];

    useEffect(() => {
        dataProvider.getList(`products/tags/collection`, {
            filter: [],
            sort: {field: 'id', order: 'ASC'},
            pagination: {page: 1, perPage: 1000},
            meta: {}
        })
            .then(({data}) => {
                const regularTags = ['kid', 'dog', 'cargo', 'bob', 'trailer', 'accessory', 'spare_part'];

                data = data.filter((tag: ProductTag) => !regularTags.includes(tag.id as string));
                data = tagGroups.concat(data);

                setTags(data);
            });
    }, []);


    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={8} lg={9}>
                {
                    editable ?
                        <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                            {/* Do not add name attribute here!!! */}
                            <TextInput source="name_translatable"
                                       label={`resources.attributes.fields.name`} validate={[required()]}/>
                            <TextInput source="tip_translatable"
                                       label={`resources.attributes.fields.tip`}/>
                            <TextInput source="description_translatable"
                                       label={`resources.attributes.fields.description`}/>

                            {valueType === 'MEASUREMENT' && <TextInput source="valueUnit_translatable"
                                                                       label={`resources.attributes.fields.unit`}
                                                                       validate={[required()]}/>}
                        </TranslatableInputs>
                        :
                        <TranslatableFields locales={['de', 'en', 'fr']}>
                            <TextField name="name_translatable" source="name_translatable"
                                       label={`resources.attributes.fields.name`}/>
                            <TextField name="tip_translatable" source="tip_translatable"
                                       label={`resources.attributes.fields.tip`}/>
                            <TextField name="description_translatable" source="description_translatable"
                                       label={`resources.attributes.fields.description`}/>
                            <TextField name="valueUnit_translatable" source="valueUnit_translatable"
                                       label={`resources.attributes.fields.unit`}/>
                        </TranslatableFields>
                }
                <FormDataConsumer>
                    {({formData}) => {

                        if (formData.valueType === 'LIST' || formData.valueType === 'LIST_MULTIPLE_VALUES') {
                            return (<List
                                resource={`attribute-values`}
                                filter={{attribute: props.record.id}}
                                pagination={false}
                                hasCreate={editable}
                                empty={false}
                                actions={editable ? <CreateButton attribute={props.record}/> : false}
                                perPage={1000}
                            >
                                <Datagrid
                                    bulkActionButtons={false}
                                    rowClick={false}
                                >
                                    <TextField
                                        source="value"
                                        sortable={false}
                                        title="resources.attributes.edit.list_of_options"
                                        label="resources.attributes.edit.list_of_options"
                                    />
                                    {
                                        editable ? <EditInDialogButton title=" " mutationMode="optimistic">
                                            <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
                                                <TranslatableInputs
                                                    locales={['de', 'en', 'fr']}
                                                    fullWidth
                                                    sx={{marginTop: 0}}
                                                >
                                                    <TextInput source="value_translatable"
                                                               label={`resources.attributes.edit.value`}/>
                                                </TranslatableInputs>
                                            </SimpleForm>
                                        </EditInDialogButton> : null
                                    }
                                    {
                                        editable ? <DeleteWithConfirmButton
                                            redirect={false}
                                            mutationMode="optimistic"
                                            confirmTitle=" "
                                        /> : null
                                    }
                                </Datagrid>
                            </List>);
                        }

                        return null;
                    }}
                </FormDataConsumer>
            </Grid>
            <Grid container item xs={12} sm={6} md={4} lg={3}>
                <Stack sx={{width: '100%'}} spacing={1}>
                    <SelectInput
                        disabled={!editable}
                        source="valueType"
                        name="valueType"
                        choices={valueTypes} fullWidth
                        label={`resources.attributes.fields.value_type`}
                        onChange={(e) => setValueType(e.target.value)}
                    />
                    <TextInput disabled={!editable} source="valueValidations" name="valueValidations" fullWidth
                               label={`resources.attributes.fields.validation_rules`}/>
                    <AutocompleteArrayInput
                        source="requiredTags"
                        name="requiredTags"
                        choices={tags}
                        fullWidth
                        disableCloseOnSelect={true}
                        blurOnSelect={false}
                        label={`resources.attributes.fields.requiredTags`}
                    />
                    <AutocompleteArrayInput
                        source="optionalTags"
                        name="optionalTags"
                        choices={tags}
                        fullWidth
                        disableCloseOnSelect={true}
                        blurOnSelect={false}
                        label={`resources.attributes.fields.optionalTags`}
                    />
                    <ReferenceArrayInput
                        reference="marketplaces"
                        source="marketplaces"
                        name="marketplaces"
                    >
                        <CheckboxGroupInput row={false} label={`resources.attributes.fields.marketplaces`}/>
                    </ReferenceArrayInput>
                </Stack>
            </Grid>
        </Grid>
    );
}


const NotEditable = (props: { record: Attribute }) => {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={8} md={8} lg={9}>
                <TranslatableFields locales={['de', 'en', 'fr']}>
                    <TextField source="name_translatable" label={`resources.attributes.fields.name`}/>
                    <TextField source="description_translatable" label={`resources.attributes.fields.description`}/>
                    <TextField source="valueUnit_translatable" label={`resources.attributes.fields.unit`}/>
                </TranslatableFields>
                <FormDataConsumer>
                    {({formData}) => {

                        if (formData.valueType === 'LIST' || formData.valueType === 'LIST_MULTIPLE_VALUES') {
                            return (<List
                                resource={`attribute-values`}
                                filter={{attribute: props.record.id}}
                                pagination={false}
                                hasCreate={true}
                                empty={false}
                                actions={false}
                                perPage={1000}
                            >
                                <Datagrid
                                    bulkActionButtons={false}
                                    rowClick={false}
                                >
                                    <TextField
                                        source="value"
                                        sortable={false}
                                        title="resources.attributes.edit.list_of_options"
                                        label="resources.attributes.edit.list_of_options"
                                    />
                                    <ShowInDialogButton fullWidth maxWidth="md" title=" ">
                                        <SimpleShowLayout>
                                            <TranslatableFields locales={['de', 'en', 'fr']}>
                                                <TextField source="value_translatable"/>
                                            </TranslatableFields>
                                        </SimpleShowLayout>
                                    </ShowInDialogButton>
                                </Datagrid>
                            </List>);
                        }
                        return null;
                    }}
                </FormDataConsumer>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={3}>
                <Stack sx={{width: '100%'}} spacing={2}>
                    <Labeled>
                        <FunctionField
                            label="resources.attributes.fields.value_type"
                            render={(record: Attribute) => {
                                const type: {
                                    id: string,
                                    name: string
                                } | undefined = valueTypes.find((type) => type.id === record.valueType);

                                return type?.name;
                            }}
                        />
                    </Labeled>
                    <Labeled>
                        <FunctionField
                            label="resources.attributes.fields.value_validations"
                            render={(record: Attribute) => (record?.valueValidations && record.valueValidations.length > 0) ? record.valueValidations : 'n/a'}
                        />
                    </Labeled>

                    <ReferenceArrayInput
                        reference="marketplaces"
                        source="marketplaces"
                        name="marketplaces"
                    >
                        <CheckboxGroupInput row={false} label={`resources.attributes.fields.marketplaces`}/>
                    </ReferenceArrayInput>
                </Stack>
            </Grid>
        </Grid>
    );
}

const Form = () => {
    const record: Attribute = useRecordContext();

    return (
        <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
            {(!record.isEditable && record?.defaultMarketplace) &&
                <DefaultMarketplaceNote marketplace={record.defaultMarketplace}/>}

            {record.isEditable ? <Editable record={record}/> :
                <NotEditable record={record}/>}
        </SimpleForm>
    );
};

const AttributeEdit = () => {
    useDefineAppLocation('settings.settings_attributes.edit');

    return (
        <Edit mutationMode="pessimistic">
            <Form/>
        </Edit>
    );
};

export default AttributeEdit;
