import * as React from "react";
import {
    SimpleForm,
    Toolbar,
    useRecordContext,
    SaveButton,
    useTranslate,
    Loading,
    useDataProvider, useNotify
} from "react-admin";
import {Category, Product, ProductMarketplaceAllocation} from "../types";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import {Grid, InputAdornment, Tooltip} from "@mui/material";
import {AccountTree} from "@mui/icons-material";
import {TreeInput, useGetTree} from "@react-admin/ra-tree";
import {arrayMoveImmutable} from "array-move";
import {useState} from "react";

const ProductCategoryEditForm = (props: { product: Product }) => {
    const record = useRecordContext<Category>();
    const t = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [savingIsLoading, setSavingIsLoading] = useState(false);

    const {data: categories, isLoading} = useGetTree(
        'category-trees',
        {
            meta: {
                filter: {
                    category: record.id
                }
            }
        }
    );

    if (isLoading) {
        return <Loading/>;
    }

    // @ts-ignore
    if (categories?.length < 2) {
        return <Tooltip title={t('resources.category.edit.missing_tree')}>
            <AccountTree/>
        </Tooltip>;
    }

    const onUpdate = (data) => {
        setSavingIsLoading(true);
        dataProvider.patch(`products/${props.product.id}/categories`, {
            data
        }).then(() => {
            notify('ra.notification.updated', {
                type: 'success',
                autoHideDuration: 5000,
                messageArgs: {smart_count: 1}
            });
            setSavingIsLoading(false);
        }).catch((resp) => {
            notify('ra.notification.http_error', {
                type: 'error',
                autoHideDuration: 5000,
            });
            setSavingIsLoading(false);
        });
    };
    const productCategories = props.product.categories.map((v) => {
        const parts = (v?.category as string).split('/');
        return parseInt(parts[parts.length - 1], 10);
    })
    return (
        <EditInDialogButton
            mutationMode="optimistic"
            icon={<AccountTree/>}
            label={'resources.category.edit.tree_label'}
            title={record.name}
        >
            <SimpleForm
                sx={{width: 500}}
                toolbar={<Toolbar><SaveButton disabled={savingIsLoading}/></Toolbar>}
                record={{categories: productCategories, category: record.id}}
                onSubmit={onUpdate}
            >
                <TreeInput
                    format={collection => {
                        return collection.map((v) => {
                            if (typeof v === 'number') {
                                return v;
                            }

                            const parts = (v?.category as string).split('/');
                            return parseInt(parts[parts.length - 1], 10);
                        });
                    }}
                    source="categories"
                    treeData={categories}
                    multiple
                    hideRootNodes
                />
            </SimpleForm>
        </EditInDialogButton>
    );
};

export default ProductCategoryEditForm;
