import * as React from 'react';
import {Card, CardHeader, CardContent, Typography, CardActions} from '@mui/material';
import {useLocaleState, useTranslate} from 'react-admin';
import {format} from 'date-fns';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {formatPrice} from "../formatUtils";

const WeeklyRevenueChart = (props: { stats?: any }) => {
    const {stats} = props;
    const translate = useTranslate();
    const [locale] = useLocaleState();
    if (!stats) return null;

    const {data, updatedAt} = stats;

    let maxRevenue = Math.max.apply(Math, data.map(function (o) {
        return parseFloat(o.currentDay) > parseFloat(o.previousWeekDay) ? parseFloat(o.currentDay) : parseFloat(o.previousWeekDay);
    }))

    const domain = [0, (Math.ceil(maxRevenue / 1500) * 1500)];

    return (
        <Card>
            <CardHeader
                title={translate('resources.reports.quickViewReports.weekly.title')}
                subheader={translate('resources.reports.quickViewReports.weekly.tip')}
            />
            <CardContent>
                <div style={{width: '100%', height: 300}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="date" tickFormatter={(date) => {
                                return new Intl.DateTimeFormat(locale, {weekday: "short"}).format(new Date(date));
                            }}/>
                            <YAxis name="Revenue" unit="€" domain={domain}/>
                            <Tooltip
                                cursor={{strokeDasharray: '3 3'}}
                                formatter={(value: any, name: any) => [formatPrice(value, 'EUR'), translate(`resources.reports.quickViewReports.weekly.${name}`)]}
                            />
                            <Legend
                                formatter={(value: any) => translate(`resources.reports.quickViewReports.weekly.${value}`)}/>
                            <Line type="linear" dataKey="currentDay" stroke="#8884d8" activeDot={{r: 8}}/>
                            <Line type="linear" dataKey="previousWeekDay" stroke="#82ca9d"/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
            <CardActions>
                <Typography variant="caption" color="textSecondary">
                    {translate('resources.reports.quickViewReports.lastUpdate', {
                        smart_count: 1,
                        date: format(new Date(updatedAt), 'dd.MM.yyyy HH:mm')
                    })}
                </Typography>
            </CardActions>
        </Card>
    );
};

export default WeeklyRevenueChart;
