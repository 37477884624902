import * as React from 'react';
import PropTypes from 'prop-types';
import {List} from '@mui/material';
import {RecordContextProvider, useListContext} from 'react-admin';

import {HitchRequestItem} from './HitchRequestItem';
import {BackInStock} from '../types';

const HitchRequestsListMobile = () => {
    const {data, isLoading, total} = useListContext<BackInStock>();
    if (isLoading || Number(total) === 0) {
        return null;
    }
    return (
        <List sx={{width: '100vw'}}>
            {data.map(backInStock => (
                <RecordContextProvider value={backInStock} key={backInStock.id}>
                    <HitchRequestItem/>
                </RecordContextProvider>
            ))}
        </List>
    );
};

HitchRequestsListMobile.propTypes = {
    data: PropTypes.any,
    hasBulkActions: PropTypes.bool.isRequired,
    ids: PropTypes.array,
    onToggleItem: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

HitchRequestsListMobile.defaultProps = {
    hasBulkActions: false,
    selectedIds: [],
};

export default HitchRequestsListMobile;
