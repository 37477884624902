import {
    mergeTranslations,
    TranslationMessages as BaseTranslationMessages,
} from 'react-admin';
import frenchMessages from 'ra-language-french';
import {RaTreeTranslationMessages} from '@react-admin/ra-tree';
import {raAuditLogLanguageFrench} from '@react-admin/ra-audit-log';
import {raTreeLanguageFrench} from '@react-admin/ra-tree';

export interface TranslationMessages
    extends RaTreeTranslationMessages,
        BaseTranslationMessages {
}

const customFrenchMessages: TranslationMessages = mergeTranslations(
    frenchMessages,
    raAuditLogLanguageFrench,
    raTreeLanguageFrench,
    {
        ra: {
            action: {
                remove_all_filters: 'Enlever tous les filtres',
            },
            saved_queries: {
                label: "Filtres mémorisés",
                new_label: 'Sauvegarder les filtres sélectionnés',
                new_dialog_title: 'Sauvegarder les filtres sélectionnés',
            },
            configurable: {
                customize: 'Anpassen',
                configureMode: 'Konfigurationsmodus',
                inspector: {
                    title: 'Inspecteur de configuration',
                    content: 'Survolez les éléments de l\'interface utilisateur de l\'application pour les configurer',
                    reset: 'Réinitialiser',
                    hideAll: 'Tout masquer',
                    showAll: 'Tout afficher',
                },
                Datagrid: {
                    title: 'Liste',
                    unlabeled: 'Colonne sans étiquette #%{column}',
                },
                SimpleForm: {
                    title: 'Formulaire',
                    unlabeled: 'Entrée non étiquetée #%{input}',
                },
                SimpleList: {
                    title: 'Liste',
                    primaryText: 'Texte principal',
                    secondaryText: 'Texte secondaire',
                    tertiaryText: 'Texte tertiaire',
                },
            },
        }
    },
    {
        'ra-form-layout': {
            action: {
                bulk_update: ``,
                next: 'Suivant',
                previous: 'Précédent',
            }
        },
        'ra-tree': {
            action: {
                add_root: 'Ajouter une catégorie de produit',
            },
            item_moved: 'Article déplacé',
            new_node: 'Nouveau nœud',
        },
        'ra-search': {
            result: `1 résultat |||| %{smart_count} résultats`,
        },
        'ra-realtime': {
            notification: {
                lock: {
                    lockedBySomeoneElse: 'L\'enregistrement est verrouillé par quelqu\'un d\'autre',
                },
            },
        },
        pos: {
            search: 'Recherche',
            configuration: 'Construction',
            language: 'Langue',
            profile: 'Mon profil',
            password: 'mot de passe',
            logout: 'Se déconnecter',
            theme: {
                name: 'Thema',
                light: 'Hell',
                dark: 'Dunkel',
                change_language: 'changer de langue',
            },
            dashboard: {
                monthly_revenue: 'Monatliche Einnahmen',
                month_history: '30 Tage Umsatzverlauf',
                new_orders: 'Neue Bestellungen',
                pending_reviews: 'Ausstehende Bewertungen',
                all_reviews: 'Alle Bewertungen ansehen',
                new_customers: 'neue Kunden',
                all_customers: 'Alle Kunden anzeigen',
                pending_orders: 'Ausstehende Bestellungen',
                order: {
                    items:
                        'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
                },
                welcome: {
                    title: 'Bienvenue au conseil d\'administration de Croozer',
                    subtitle:
                        'Il est utilisé pour gérer les places de marché.',
                    ra_button: 'react-admin site',
                    demo_button: 'Source for this demo',
                },
            },
            menu: {
                sales: 'Der Umsatz',
                catalog: 'Catalogue',
                customers: 'Kunden',
            },
        },
    },
    {
        resources: {
            sales_menu_label: 'Ventes',
            catalog_menu_label: 'Catalogue',
            back_in_stock: {
                main_menu_label: 'De nouveau disponible',
                request_id: 'Identificatif de la demande',
                email: 'E-mail',
                requested_sku: 'Numéro du produit',
                product_name: 'Nom du produit',
                lang: 'Langue',
                requested_at: 'Demandé le',
                notified_at: 'Informé le',
                is_notified: 'Benachrichtigung',
                details_label: 'Détail de la demande',
                notified: 'Informé le',
                notified_since: 'Notification envoyée après le',
                notified_before: 'Notification envoyée avant le',
                requested_since: 'Demandes de renseignements après le',
                requested_before: 'Demandes de renseignements avant le',
                is_outdated: 'N´est plus actuel',
                yes: 'Oui',
                no: 'Non',
                outdated: 'Veraltet',
            },
            server: {
                main_menu_label: 'Paramètres de l´application',
                cron: {
                    main_menu_label: 'Job du serveur',
                    page_title: 'Vorschauseite für Serverjobs',
                    page_list_title: 'Liste der Serverjobs',
                    when: 'Si',
                    add_new_period: 'Ajouter une nouvelle période de maintenance',
                    id: 'Identité du job du serveur',
                    jobInfo: 'Serverjob info',
                    time: 'Code temporel',
                    name: 'Nom du job du serveur',
                    description: 'Description du job du serveur',
                    time_description: 'Zeit Bezeichnung',
                    delete_schedule_question:
                        'Möchten Sie dieses Element wirklich löschen?',
                    delete_schedule: 'Enlever',
                    start: 'Heure de début',
                    end: 'Heure de fin',
                    no_maintenance_periods: 'Noch keine Wartungsperioden definiert',
                    no_maintenance_periods_tip:
                        'Bitte verwenden Sie das Seitenformular, um ein neues Wartungsfenster für den Serverjob zu definieren.',
                    validation: {
                        start_end_comparison:
                            'Das Enddatum sollte immer nach dem Startdatum liegen',
                    },
                    created_successfully: 'Zeitraum erfolgreich erstellt',
                    schedules_remaining: 'Temps restant pour l´entretien',
                    state: 'État actuel',
                    running: 'LAUFEND',
                    stopped: 'GESTOPPT',
                    waiting: 'Warten',
                    archived: 'Archiviert',
                    active: 'Actif',
                    schedule_state: 'État',
                    created_by_label: 'Fait par',
                },
            },
            order: {
                main_menu_label: 'Commandes',
                is_sent_to_dealer: 'Au concessionnaire',
                is_sent_to_parcel_locker: 'Vers le compartiment à colis',
                totalAmount: 'Prix total',
                status: 'Statut',
                initiated_at: 'Initié le',
                number: 'N° de commande',
                cart_reference_id: 'Identifiant de référence du panier',
                discounted: 'Coupons',
                filters: {
                    headline: 'Filtres',
                    status: 'Statut',
                    email: 'Kunden-eMail',
                    yes: 'Oui',
                    no: 'Non',
                    search: 'Suche',
                    tooltip:
                        'Geben Sie unten etwas ein, um Suchergebnisse basierend auf Kunden-E-Mail oder Bestellnummer zu erhalten.',
                },
                statuses: {
                    new: 'Nouveau',
                    payment_pending: 'Paiement en suspens',
                    payment_redirect: 'Traitement des paiements',
                    payment_complete: 'Paiement terminé',
                    paid: 'Payé',
                    sent_to_select_line: 'Exporté vers SL',
                    fully_refunded: 'Entièrement remboursé',
                    partially_refunded: 'Partiellement remboursé',
                    ordered: 'Ordonné',
                    refund_details: 'Détails du remboursement',
                    refunded_items: 'Article remboursé',
                    refunded_at: 'Remboursement à',
                    shipping_refund_label: 'remboursement',
                    shipping_refund_description: 'remboursement',
                },
                show: {
                    title: 'Aufträge %{reference}',
                    headline: 'Commandes',
                    shipping_same_as_billing: 'Expédition comme facture',
                    none: '-',
                    billing_address: 'Adresse de facturation',
                    shipping_address: 'Adressse de livraison',
                    dealer_address: 'Adresse du revendeur',
                    items: 'Produits commandés',
                    product_name: 'Nom',
                    product_sku: 'N° article',
                    product_price: 'Preis',
                    product_quantity: 'Menge',
                    product_total: 'Total',
                    total: 'Somme',
                    products_subtotal: 'Sous-total',
                    shipping_cost: 'Envoi',
                    order_total: 'Total',
                    discount: 'Rabatt',
                    coupon: 'Coupons',
                    coupon_code: 'Coupon code',
                    free_product_sku: 'Produit gratuit-N° article',
                    discount_amount: 'Quantité',
                    applied_at: 'Beworben bei',
                    placed_at: 'Placé le',
                    sent_to_sl_at: 'Envoyé à SL le ',
                    sl_customer_id: 'N° de client SL',
                    sl_order_number: 'N° de commande SL',
                    dealer_id: 'Numéro d\'identification du concessionnaire',
                    dhl_post_number: 'Code  postal',
                    is_refunded: 'Wird erstattet?',
                },
                source: 'Canal de vente',
            },
            coupon: {
                main_menu_label: 'Coupons',
                filters: {
                    status: 'Statut',
                    draft: 'Brouillon',
                    on_hold: 'En attente',
                    active: 'Actif',
                    deactivated: 'Désactivé',
                    operative: 'Opératif',
                    yes: 'Oui',
                    no: 'Non',
                },
                validation: {
                    start_end_comparison:
                        'Das Enddatum sollte immer nach dem Startdatum liegen',
                },
                create_coupon: 'Neuen Gutschein erstellen',
                base_data_label: 'Description',
                name: 'Nom',
                description: 'Description',
                period_of_time: 'Période de validité',
                base: 'Grundinformation',
                conditions: 'Conditions',
                target: 'Avantage pour le client',
                codes: 'Codes',
                order_min_value: 'Mindestwert bestellen',
                order_value: 'Wert',
                mandatory_product: 'Pflichtprodukt',
                condition_request: 'Si',
                product: 'Produit',
                target_type: 'Type de prestation',
                condition_type: 'Utiliser sur',
                free_product: 'Produit gratuit',
                money: 'Remise en €',
                percentage: 'Remise en %',
                discount_value: 'Zielwert',
                percentage_value: 'Zielwert',
                amount_value: 'Höhe des Rabatts',
                select_target_type: 'Wählen Sie zuerst den Zieltyp aus',
                select_condition_type: 'Wählen Sie zuerst die Konditionsart aus',
                order: 'Montant de la commande',
                email: 'Email',
                category: 'Catégorie',
                marked: 'Produits marqués',
                select_product: 'Produit sélectionné',
                flag: 'Flagge',
                email_list: 'Liste der E-Mails',
                code: 'Code',
                new_codes_list: 'Fügen Sie unten neuen Code hinzu',
                code_generator_description:
                    'Bitte geben Sie an, wie viele Codes Sie benötigen, wie lang sie sein sollen (Anzahl der Zeichen) und die Art des Limits.',
                emails_conditions_tip:
                    'Bitte geben Sie eine E-Mail-Adresse pro Zeile ein.',
                code_generator: 'Batch-Code-Generator',
                generate_batch: 'Générateur automatique de codes',
                codes_quantity: 'Menge an Codes',
                create: 'Schaffen',
                one_time_use: 'Usage unique',
                all_one_time_use: 'Usage unique',
                close: 'Schließen',
                generate: 'Generieren',
                updated_successfully: 'Gutschein erfolgreich aktualisiert',
                list_of_coupons: 'Liste der Coupons',
                edit_page_title: 'Coupon Details',
                status: {
                    label: 'Statut',
                    list_label: 'Liste de statut',
                    draft: 'Brouillon',
                    active: 'Actif',
                    on_hold: 'En attente',
                    deactivated: 'Désactivé',
                    draft_details:
                        'Die erste Ebene des Gutscheins. Im Entwurfsstatus können Sie die meisten Felder leer lassen.',
                    active_details:
                        'Der wichtigste Zustand. Dieser Status ermöglicht es dem Kunden, Gutscheincodes zwischen dem Start- und Enddatum zu verwenden. Wenn Sie aktiv sein möchten, müssen Sie fast alle Gutscheinfelder ausfüllen und mindestens einen Gutscheincode hinzufügen.',
                    on_hold_details:
                        'Verwenden Sie diesen, um Gutscheincodes für eine Weile auszusetzen.',
                    deactivated_details:
                        'Letzte Stufe des Gutscheins. Wenn Sie dies eingerichtet haben, gibt es kein Zurück mehr.',
                },
                create_new: 'Nouveau',
                start_date: 'Von',
                end_date: 'Bis',
                delete_coupon: '%{name} löschen',
                delete_coupon_question:
                    'Möchten Sie diesen Coupon wirklich löschen?',

                start_date_label: 'Date de début',
                created_by_label: 'Fait par',
                info: {
                    label: 'Info',
                    created_at_label: 'Créé le',
                    updated_by_label: 'Actualisé la dernière fois par',
                    updated_at_label: 'Actualisé la dernière fois à ',
                },
                report: {
                    label: 'Rapport',
                    created_by: 'Fait par',
                    one_time_use: 'Usage unique',
                    is_used: 'Utilisée',
                    is_active: 'Actif',
                    date_of_last_usage: 'Dernière utilisation à',
                    added_to_cart_at: 'Utilisé à',
                    cart_reference_id: 'Identifiant de référence du panier',
                    is_redeemed: 'Wird eingelöst',
                    order_number: 'N° de commande',
                    discount_amount: 'Montant de la remise',
                    nothing_yet: 'Aucune entrée disponible',
                    order_placed_at: 'Bestellung aufgegeben bei',
                },
            },
            products: {
                main_menu_label: 'Produits',
                name: 'Product |||| Products',
                fields: {
                    category_id: 'Category',
                    height_gte: 'Min height',
                    height_lte: 'Max height',
                    height: 'Height',
                    image: 'Image',
                    price: 'Price',
                    reference: 'Reference',
                    sales: 'Sales',
                    stock_lte: 'Low Stock',
                    stock: 'Stock',
                    thumbnail: 'Thumbnail',
                    width_gte: 'Min width',
                    width_lte: 'Max width',
                    width: 'Width',
                },
                tabs: {
                    image: 'Image',
                    details: 'Details',
                    description: 'Description',
                    reviews: 'Reviews',
                },
                filters: {
                    categories: 'Catégories',
                    stock: 'Inventar',
                    no_stock: 'Épuisé',
                    low_stock: '1 - 9 items',
                    average_stock: '10 - 49 items',
                    enough_stock: '50 items & more',
                    sales: 'Sales',
                    best_sellers: 'Best sellers',
                    average_sellers: 'Average',
                    low_sellers: 'Low',
                    never_sold: 'Never sold',
                    is_retail_ready: 'Prêt pour la commerce de détail?',
                    is_wholesale_ready: 'Commerce de gros prêt à l\'emploi',
                    is_bundle: 'Est-ce un kit?',
                    untagged: 'Sans mot clés',
                    stock_balance: 'État des stocks',
                    phased_out_date: 'Date d´expiration',
                    included_archived: 'Inclure les archivés',
                    reset_filters: 'Réinitialiser le filtre',
                    search_phrase: 'Recherche',
                    stock_balance_filter: 'Stock est',
                },
                list: {
                    archive: 'Archive',
                    name: 'Nom du produit',
                    memo: 'Description',
                    retail_ready: 'vente au détail',
                    wholesale_ready: 'de gros',
                    is_bundle: 'Bundle',
                    net_price: 'Prix net',
                    update_marketplace: 'Marché',
                    operation: 'Type d\'opération',
                    attach: 'Attacher',
                    detach: 'Détacher',
                    update_marketplace_dialog_title: 'Mise à jour de l\'allocation du marché',
                    marketplace_update_attach_hint: 'Les produits seront ajoutés au marché au prix actuellement en vigueur pour le marché par défaut.',
                    marketplace_update_detach_hint: 'Sélectionnez la place de marché à partir de laquelle supprimer les produits.',
                    update_tags: 'Mots clés',
                    update_tags_dialog_title: 'Attribution des balises',
                    bulk_delete_content:
                        'Êtes-vous sûr de vouloir archiver ce produit ? |||| Es-tu sûr que tu aimes ça %{smart_count} Vous souhaitez archiver des éléments ?',
                    stock: 'Entrepôt',
                    phasingOutDate: 'Date d´expiration',
                    oldUrl: 'URL',
                    distriDiscount: 'Remise pour distributeur',
                    regularDiscount: 'Remise pour les revendeurs ',
                    ean: 'EAN/CUP',
                    tags: 'Mots clés',
                    sku: 'N° article',
                    color: 'Couleur',
                    size: 'Sièges/Taille',
                    isDealerDedicated: 'Recherche de revendeur',
                    flag: 'Drapeau',
                    update_attributes: 'Mise à jour des attributs',
                    update_attribute_dialog_title: 'Mise à jour des attributs',
                    warning_label: 'Attention: ',
                    warning_message: 'Veuillez vérifier vos modifications attentivement avant de les soumettre. Cette action est irréversible.',
                },
                flags: {
                    new: 'Nouveau',
                    isComingSoon: 'Bientôt',
                    discontinued: 'Expirés'
                },
                show: {
                    color: 'Couleur',
                    size: 'Sièges/Taille',
                    base: 'Informations de base',
                    sku: 'N° article',
                    ean: 'EAN',
                    price: 'Prix',
                    categories: 'Catégories',
                    early_bird: 'Ist early bird?',
                    retail_ready: 'vente au détail',
                    wholesale_ready: 'Commerce de gros prêt à l\'emploi',
                    is_bundle: 'Bundle',
                    net_price: 'Prix net',
                    regular_discount: 'Remise pour les revendeurs ',
                    distributor_discount: 'Remise pour distributeur',
                    prices: 'Liste de prix',
                    marketplace_allocations: 'Allocations du marché',
                    marketplace: 'Marché',
                    rrp_message: 'Prix ​​de vente conseillé (pas pour les mises à jour)',
                    promo_price: 'Prix ​​net promotionnel',
                    marketplace_allocation: 'Attribuer un produit sur le marché',
                    full_price: 'Prix actuel TTC',
                    price_error_format: 'Format de prix incorrect. Exemple: 12.34 ou 9.34562',
                    manuals: 'Mode d´emploi',
                    phased_out_date: 'Date d´expiration',
                    product_attributes: 'Données techniques',
                    product_content: 'Description du produit',
                    attribute_name: 'Nom d\'attribut',
                    attribute_value: 'Valeur d\'attribut',
                    product_media: 'Supports produits',
                    select_attribute_first: 'Sélectionnez d\'abord un attribut',
                    marketplace_name: 'Nom de la plateforme',
                    min_inventory: 'Quantité minimum',
                    is_active: 'Est activé',
                    download_qr_code_file: 'Télécharger le fichier avec le code QR',
                    manual_language: 'Langue',
                    manual_type: 'Type',
                    manual_source_file: 'Quelldatei',
                    manual_scans: 'Scannt',
                    manual_file_delete_title: 'Die Datei wird entfernt',
                    attribute_desc: 'Description',
                    image_position_updated: 'Bildposition aktualisiert',
                    accepted_files_format: 'Sie können diese unterstützten Dateitypen hochladen: webp, jpeg, jpg, png, mp4',
                    accepted_files_format_colors_size: 'Sie können diese unterstützten Dateitypen hochladen: png, jpeg, jpg, svg',
                    color_image: 'représentation de l\'image en couleur',
                    size_image: 'Taille de la représentation de l\'image',
                    update_colour_image_title: 'Mettre à jour la représentation de l\'image couleur',
                    update_size_image_title: 'Mettre à jour la taille de l\'image',
                    add_colour_image_title: 'Ajouter une représentation d\'image en couleur',
                    add_size_image_title: 'Ajouter une taille à l\'affichage de l\'image',
                    compatible_trailers: 'Remorques compatibles',
                    compatible_accessories: 'Accessoires compatibles',
                    compatible_spare_parts: 'Pièces de rechange compatibles',
                    product_attributes_mp_filter: 'Filtrer par marché',
                    own_product_attributes: 'Attributs de produit propres',
                    content: {
                        name: 'Nom',
                        description: 'Description',
                        short_description: 'Kurzbeschreibung',
                        delivery_time: 'Délai de livraison',
                        meta_title: 'Méta-titre',
                        meta_description: 'Meta Description',
                        size: 'Taille',
                        slug: 'Slug',
                        url: 'Ancienne URL',
                        gallery: 'Galerie',
                        image: 'Photos',
                        title: 'Titre',
                        color_image: 'Photo en couleur',
                        size_image: 'grande photo',
                        video: 'Video',
                        videoTranslatable: 'Vidéo',
                    },
                    variants: {
                        add_new_variant: 'Ajouter une nouvelle variante',
                        section_name: 'Variantes',
                        name: 'Nom',
                        color: 'Couleur',
                        size: 'Taille',
                        attribute: 'Attribut',
                        sku: 'N° article',
                        variant: 'Variante',
                        variant_attribute: 'Variante Attribut',
                        variant_value: 'Valeur d\'attribut de variante'
                    },
                    matched: {
                        add_new_product: 'Ajouter de nouveaux produits compatibles',
                        section_name: 'Compatibilités',
                        name: 'Nom',
                        sku: 'N° article',
                        product: 'Produit',
                        products: 'Produits',
                        bulk_delete_content:
                            'Êtes-vous sûr de vouloir résoudre ce produit ? |||| Etes-vous sûr de vouloir résoudre ces %{smart_count} éléments ?',
                    },
                },
                aside: {
                    stocks_headline: 'Stock',
                    sale_ready_stock: 'Stock prêt à la vente',
                    purchase_order_headline: 'Liste de commandes',
                    arrival_date: 'Date d\'arrivée',
                    purchase: 'Commande',
                    arrival: 'WE-Vorschlag',
                    ordered_items: 'Article commandé: %{smart_count}',
                    remaining_items: 'Stock restant: %{smart_count}',
                    available_items: 'Articles disponibles: %{smart_count}',
                    settings_headline: 'Paramètres',
                }
            },
            user: {
                name: 'Utilisateur',
                create_user: 'Créer un nouvel utilisateur',
                warning: 'Danger',
                something_went_wrong: 'Something went wrong.',
                fields: {
                    fullName: 'Nom et prénom',
                    firstName: 'Prénom',
                    lastName: 'Nom',
                    phone: 'Téléphone',
                    roleName: 'Nom du rôle',
                    email: 'Email',
                    accessRole: 'Rôle d´autorisation',
                    newPassword: 'Nouveau mot de passe',
                    oldPassword: 'Ancien mot de passe',
                    repeatNewPassword: 'Repéter le nouveau mot de passe',
                    switchOn: 'Allumé',
                    switchOff: 'Éteint',
                    isMfaEnabled: 'Authentification multifactorielle activée',
                },
                fieldGroups: {
                    identity: 'Identité',
                    change_password: 'Changer le moot de passe',
                    contact: 'Contact',
                    access_role: 'Rôle d\'accès',
                    password: 'Passwort',
                    mfa: 'Authentification à deux facteurs',
                },
                validation: {
                    password_dont_match: 'Passwort stimmt nicht überein',
                },
                updated_successfully: 'Benutzer erfolgreich aktualisiert',
                created_successfully: 'Benutzer erfolgreich erstellt',
                force_mfa: 'Multi-Faktor-Authentifizierung erzwingen',
                force_mfa_success: 'Multi-Faktor-Authentifizierung gezwungen',
                force_mfa_error: 'Multi-Faktor-Authentifizierung Fehler',
                you_need_to_setup_mfa:
                    'Einrichtung der Multi-Faktor-Authentifizierung.',
                mfa_headline_subtitle:
                    'En plus du mot de passe, tu as besoin d´un code de sécurité. Pour cela, tu as bespoin d´une application (par ex. Authy ou Google Authenticator) et de ton téléphone portable pour scanner le QR code.',
                mfa_dialog_title: 'Authentification à deux facteurs',
                disable_mfa_dialog_title: 'Ausschalten',
                disable_mfa_dialog_content:
                    'Bist Du Dir sicher, dass Du das registrierte Gerät entfernen und die Zwei-Faktor-Authentifizierung deaktivieren möchtest?',
                disable_mfa_disallowed:
                    'Du kannst diese Einstellung nicht aktualisieren.',
                disable_mfa_disallowed_2:
                    'Du kannst die Einstellung für die Zwei-Faktor-Authentifizierung nicht deaktivieren.',
                six_digits_validation:
                    'Du musst 6 Ziffern eingeben, um ein Gerät hinzuzufügen.',
                type_code_below:
                    'Verwenden Sie Ihre mobile App, um den Authentifizierungscode zu erhalten, und geben Sie ihn unten ein.',
                verify_code: 'Verifizieren',
                mfa_dialog_subtitle:
                    'Du benötigst hierfür eine Google Authentifikations App um den Prozess zu abzuschließen:',
                scanQrCode: 'Scanne den QR Code mit Hilfe der App',
                enterSixDigitCode:
                    'Gib den von Deiner App generierten 6-stelligen Code in die Felder ein:',
                mobileAppList: 'Liste von mobilen Apps',
                addDevice: 'Füge das Gerät hinzu',
                reset_password: 'Réinitialiser le mot de passe',
                reset_password_button: 'Réinitialiser',
                reset_password_instruction: 'Pour réinitialiser votre mot de passe, suivez ces étapes simples :',
                reset_password_instruction_step1: '1. Saisissez l\'adresse e-mail associée à votre compte.',
                reset_password_instruction_step2: '2. Vérifiez vos e-mails pour un lien de réinitialisation du mot de passe.',
                reset_password_instruction_step3: '3. Suivez les instructions dans l\'e-mail pour créer un nouveau mot de passe.',
                reset_password_instruction_footer: 'Si vous ne recevez pas l\'e-mail dans quelques minutes, veuillez vérifier votre dossier de spam ou réessayez.',
                reset_password_warning: 'Une erreur est survenue. Veuillez réessayer plus tard ou contacter l\'administrateur. ',
                reset_password_success: 'Succès ! Continuez comme indiqué.',
                final_reset_password_button: 'Actualiser mon mot de passe',
                password_strength_requirements_title: 'Exigences de sécurité du mot de passe:',
                password_strength_requirements_1: 'Au moins 8 caractères',
                password_strength_requirements_2: 'Inclut au moins une majuscule',
                password_strength_requirements_3: 'Inclut au moins une minuscule',
                password_strength_requirements_4: 'Contient au moins un chiffre',
                password_strength_requirements_5: 'Contient au moins un caractère spécial (par ex. !, @, #, $, etc.)',
            },
            category: {
                edit: {
                    tree_label: 'Arbre des categories',
                    tree: 'Baumvorschau',
                    internal_desc: 'Beschreibung (nur interner Zweck)',
                    is_default: 'Avoir standard',
                    edit_tree: 'Baum bearbeiten',
                    add_tree: 'Neuen Baum hinzufügen',
                    new_category_dialog_title: 'Neue Kategorie hinzufügen',
                    new_child_node: 'Neue Knoten',
                    missing_tree: 'Der Baum für diese Kategorie fehlt.',
                    associated_products: 'Zugehörige Produkte',
                    remove_below_category: 'Untere Kategorie entfernen:',
                    name: 'Nom',
                    title: 'Titre',
                }
            },
            marketplace: {
                main_menu_label: 'Places de marché',
                edit: {
                    category_list_title: 'Liste des catégories',
                    category_id: 'Identité de la catégorie',
                    category_name: 'Nom de la catégorie',
                    category_code: 'Code',
                    category_default: 'Avoir standard',
                    name: 'Nom',
                    country: 'Indicatif du pays',
                    description: 'Description',
                    is_active: 'Est activé',
                    list_of_products: 'Liste der zugeordneten Produkte',
                    product_sku: 'N° article',
                    product: 'Produit',
                    net_price: 'Prix net',
                    stock: 'Stock',
                    promo_net_price: 'Prix net promotionnel',
                    min_inventory: 'Quantité minimum',
                    req_completion_percentage: 'Taux de remplissage des champs obligatoires',
                    opt_completion_percentage: 'Taux de remplissage des champs optionnels',
                    requiredAttributes: 'Champs obligatoires',
                    optionalAttributes: 'Champs optionnels',
                    completed: 'Terminé?',
                },
                fields: {
                    name: 'Nom de la plateforme',
                    proxy: 'Proxy',
                    country_code: 'Indicatif du pays',
                    is_active: 'Est activé',
                    is_retail: 'Dédié au commerce de détail',
                }
            },
            attributes: {
                main_menu_label: 'Caractéristiques du produit',
                edit: {
                    default_marketplace_note: 'Sie sind derzeit dabei, ein Attribut zu bearbeiten, das mit einem der Marktplätze verbunden ist: %{marketplace}. Dieses Attribut stammt direkt aus der %{marketplace}-Infrastruktur. Bitte beachten Sie, dass bestimmte Attribute, die von externen Diensten stammen, in begrenztem Umfang bearbeitet werden können, hauptsächlich um die Datenkonsistenz aufrechtzuerhalten. Sie haben jedoch weiterhin die Möglichkeit, dieses Attribut auf einem anderen Marktplatz zu verwenden.',
                    list_of_options: 'Liste der Optionen',
                    add_new_option: 'Neue Option hinzufügen',
                    value: 'Wert',
                },
                fields: {
                    name: 'Nom',
                    description: 'Description',
                    unit: 'Unité',
                    validation_rules: 'Règles de validation',
                    marketplaces: 'Places de marché',
                    value_type: 'Type de valeur',
                    value_validations: 'Wertvalidierungen',
                    has_closed_options: 'Hat geschlossene Optionen',
                    origin_marketplace: 'marché d\'origine',
                    tip: 'Conseil',
                    requiredTags: 'Obligatoire pour les produits avec ces balises',
                    optionalTags: 'Facultatif pour les produits avec ces balises',
                }
            },
            reports: {
                main_menu_label: 'Rapportes',
                products_allocations_main_menu_label: 'Répartition des produits',
                hitch_request_main_menu_label: 'Liste des demandes d\'attelage',
                allocations: {
                    all_tab: 'Tous',
                },
                reports: {
                    main_menu_label: 'Rapportes',
                    main_menu_label_bc: 'Liste des rapports',
                    create_new_report: 'Créer un nouveau rapport',
                    show: {
                        listOfRequirements: 'Liste des exigences',
                        listOfFiles: 'Liste des fichiers',
                    },
                    list: {
                        id: 'Identité',
                        name: 'Nom',
                        type: 'Type',
                        frequency: 'Fréquence',
                        status: 'Statut',
                        createdAt: 'Créé le',
                        createdBy: 'Fait par',
                        isPrivate: 'Privé',
                    },
                    types: {
                        revenue: 'Chiffre d\'affaires',
                        best_selling_products: 'Produits les plus vendus',
                        refunded_products: 'Produits remboursés',
                        total: 'Total',
                        total_by_marketplace: 'Total par place de marché',
                        total_by_day: 'Total par jour',
                        total_by_month: 'Total par mois',
                        by_day_marketplace: 'Place de marché par jour',
                        by_month_marketplace: 'Place de marché par mois',
                        coupons: 'Revenus des coupons',
                        total_by_country: 'Total par pays (expédition)',
                    },
                    filters: {
                        name: 'Nom du report',
                        status: 'Statut',
                        type: 'Type',
                        frequency: 'Fréquence',
                        isPrivate: 'Privé',
                        isPrivateHelper: 'Si vous souhaitez que le rapport ne soit visible que par vous, activez cette option.',
                        groupBy: 'Regrouper par',
                        groupByHelper: 'Sélectionnez la méthode de regroupement pour le rapport.',
                        withRefunded: 'Inclure les produits remboursés',
                        withRefundedHelper: 'Si vous souhaitez inclure les produits remboursés dans le rapport, veuillez cocher cette case.',
                        withRefundedOrders: 'Inclure les commandes remboursées',
                        withRefundedOrdersHelper: 'Si vous souhaitez inclure les commandes remboursées dans le rapport, veuillez cocher cette case.',
                        withShippingCost: 'VInclure les frais d\'expédition',
                        withShippingCostHelper: 'Si vous souhaitez inclure les frais d\'expédition dans le rapport, veuillez cocher cette case.',
                    },
                    status: {
                        new: 'Nouveau',
                        done: 'Prêt',
                        running: 'En cours d\'exécution',
                    },
                    frequency: {
                        one_time: 'Une seule fois',
                        recurring: 'Récurrent',
                        daily: 'chaque jour',
                        weekly: 'chaque semaine',
                        monthly: 'chaque mois',
                        time_range: 'Date préférée pour la réception du rapport',
                        report_frequency: 'Fréquence des rapportes',
                    },
                    create: {
                        first_step_name: 'Informations de base',
                        name: 'Nom du report',
                        description: 'Descriptif interne',
                        marketplaces: 'Places de marché',
                        dates: 'Plage de dates',
                        dateFrom: 'Depuis',
                        dateTo: 'Jusqu\'à',
                        start_end_comparison: 'La date de fin doit toujours être postérieure à la date de début.',
                        recurring_tip: 'Sélectionnez la fréquence du rapport.',
                        recurring_tip_daily: 'Vous recevrez le rapport quotidiennement. Le rapport est basé sur les données de la veille.',
                        recurring_tip_weekly: 'Vous recevrez le rapport tous les lundis. Le rapport est basé sur les données de la semaine précédente.',
                        recurring_tip_monthly: 'Vous recevrez le rapport le premier jour du mois. Le rapport est basé sur les données du mois précédent.',
                        daily_time_tip: 'Sélectionnez le moment de la journée où vous souhaitez recevoir le rapport.',
                        one_time_tip: 'Veuillez sélectionner la plage de dates pour récupérer les commerciales et générer votre rapport.',
                        end_date: 'Date de fin',
                        end_date_tip: 'Sélectionnez la date à laquelle le rapport a été généré pour la dernière fois.',
                        groupBy: 'Regrouper par',
                        isRefundsInclus: 'Inclure les articles remboursés',
                    },
                    files: {
                        created_at: 'Créé le',
                        file_name: 'Dateiname',
                        downloads_number: 'Nombre de téléchargements',
                        download: 'Télécharger',
                        empty_recurring: 'Vous trouverez ici la liste des rapports générés une fois qu\'au moins un est prêt.',
                        empty_one_time: 'Votre rapport est en cours de création. S\'il vous plaît, attendez...',
                        expiration: 'Le lien ci-dessus expire dans:',
                    },
                    hitchRequest: {
                        main_menu_label: 'Demandes d\'attelage',
                        hitch_type: 'Type d\'attelage',
                        requested_at: 'Demandé à',
                        first_measurement: 'Mesure 1',
                        second_measurement: 'Mesure 2',
                        status: 'Statut',
                        language: 'Langue',
                        internalNote: 'Note interne',
                        phone: 'Téléphone',
                        open: 'Ouvrir',
                        close: 'Fermer',
                    }
                },
                quickViewReports: {
                    lastUpdate: 'Données mises à jour le: %{date}',
                    weekly: {
                        title: 'Rapport hebdomadaire actuel',
                        tip: 'Ce rapport est basé sur la semaine en cours et compare les résultats à la semaine précédente.',
                        currentDay: 'Semaine en cours',
                        previousWeekDay: 'Semaine précédente',
                        revenue: 'revenu',
                    },
                    monthly: {
                        title: 'Rapport mensuel',
                        all: 'Tous les marchés',
                    },
                    bestSellers: {
                        items: 'Un article se vend |||| %{nb_items} articles ont été vendus',
                        currentYear: 'Best-seller de l\'année en cours',
                        currentMonth: 'Best-seller du mois en cours',
                        currentWeek: 'Best-seller de la semaine en cours',
                        tip: 'Basé sur les données collectées sur tous les marchés de détail.',
                        year: 'année',
                        month: 'Mois',
                        week: 'semaine'
                    },
                    daily: {
                        revenue: 'Revenu quotidien',
                        orders: 'Nombre de commandes',
                    },
                    stateOfContent: {
                        title: 'État du contenu',
                        tip: 'Découvrez un aperçu des places de marché, affichant le nombre total de produits, le nombre d\'entrées correctement complétées, et la date du dernier export pour chaque place de marché.',
                        lastExport: 'Exporté à: %{date}',
                        tooltip: '%{correct} des %{total} entrées sont correctement renseignées.',
                    },
                }
            },
            dealers: {
                locations: {
                    main_menu_label: 'Adresses des concessionnaires',
                },
                main_menu_label: 'Concessionnaire',
                list: {
                    name: 'Nom',
                    selectLineId: 'Numéro',
                    isServicePartner: 'Partenaire de service',
                    country: 'Pays',
                    groupNumber: 'Groupe de clients',
                    industry: 'Secteur',
                    isActive: 'Est activé',
                    creationDate: 'Erstellungsdatum',
                    city: 'Ville',
                    street: 'Adresse',
                    zipCode: 'Code postal',
                    phoneNumber1: 'Numéro de téléphone',
                    website: 'Site web',
                    isDocumentLock: 'Est bloqué',
                    bulk_delete_content:
                        'Sind Sie sicher, dass Sie dieses Händler lösen möchten? |||| Sind Sie sicher, dass Sie diese %{smart_count} Elemente lösen möchten?',
                    archive: 'Archiv',
                    addressLine1: 'Complémentaire',
                    addressLine2: 'Zusatz 2',
                    assortment: 'Gamme de produits',
                    accountGroupNumber: 'Groupe de comptes',
                    email: 'E-mail',
                },
                show: {
                    name: 'Nom',
                    selectLineId: 'Numéro',
                    isServicePartner: 'Partenaire de service',
                    country: 'Pays',
                    groupNumber: 'Groupe de clients',
                    accountGroupNumber: 'Groupe de comptes',
                    industry: 'Secteur',
                    isActive: 'Est activé',
                    creationDate: 'Erstellungsdatum',
                    city: 'Ville',
                    street: 'Adresse',
                    zipCode: 'Code postal',
                    phoneNumber1: 'Numéro de téléphone',
                    website: 'Site web',
                    isDocumentLock: 'Est bloqué',
                    bulk_delete_content:
                        'Are you sure you want to archive this %{name}? |||| Are you sure you want to archive these %{smart_count} items?',
                    archive: 'Archiv',
                    addressLine1: 'Complémentaire',
                    addressLine2: 'Zusatz 2',
                    tax: 'Identifiant fiscal',
                    language: 'Langue',
                    isPrivatePerson: '',
                    lockReason: 'Sperrgrund',
                    address_contact: 'Adresse & contact',
                    locations: 'Filiale',
                    phoneNumber2: 'Telefonnummer 2',
                    phoneNumber3: 'Telefonnummer 3',
                    phoneNumber4: 'Telefonnummer 4',
                    email: 'E-mail',
                    email2: 'E-mail 2',
                    faxNumber: 'Numéro de fax',
                    address: 'Adresse',
                    contact: 'Contact',
                },
                address: {
                    dealer: {
                        name: 'Concessionnaire'
                    },
                    selectLineId: 'Numéro',
                    firstName: 'Prénom',
                    lastName: 'Nom',
                    country: 'Pays',
                    isDefault: 'Avoir standard',
                    city: 'Ville',
                    street: 'Adresse',
                    zipCode: 'Code postal',
                    phoneNumber1: 'Numéro de téléphone',
                    phoneNumber2: 'Telefonnummer 2',
                    phoneNumber3: 'Telefonnummer 3',
                    phoneNumber4: 'Telefonnummer 4',
                    website: 'Site web',
                    addressLine1: 'Complémentaire',
                    addressLine2: 'Zusatz 2',
                    addressLine3: 'Zusatz 3',
                    pickup_point: 'Abholpunkt',
                    service_point: 'Servicepunkt',
                    physical_store: 'Ladengeschäft',
                    functions: 'Fonctionnalités',
                    function: 'Funktion',
                    department: 'Département',
                    salutation: 'Formule d\'appel',
                    latitude: 'Latitude géofrafique',
                    longitude: 'Longitude géografique ',
                    email: 'E-mail',
                    email2: 'E-mail 2',
                    faxNumber: 'Numéro de fax',
                    erpFunction: 'Funktion',
                    lat: 'Latitude géofrafique',
                    lon: 'Longitude géografique ',
                },
                filters: {
                    included_archived: 'Inclure les archivés',
                    reset_filters: 'Réinitialiser le filtre',
                    search_phrase: 'Chercher',
                },
                aside: {
                    internal_note_headline: 'Note interne',
                    lastOrderPlaced: 'Letzte Bestellung',
                    lastHalfYearStats: 'Statistik der letzten 6 Monate',
                    stats: 'Statistik',
                    productTypes: 'Types de produits',
                    stats_info: 'Hinweis: Die Statistiken basieren auf Bestellungen der letzten 180 Tage.',
                    assortment_info: 'Beispiel: \'Kid (80%)\' bedeutet, dass 42% aller gekauften Artikel für Kinder bestimmt waren.',
                    type_info: 'Beispiel: \'Trailers (15/45)\' bedeutet, dass der Händler in den letzten 180 Tagen 82 Artikel gekauft hat, darunter 9 Anhänger.',
                    number_of_orders_stats: 'Anzahl der Bestellungen',
                    list_of_dates: 'Liste der Bestelldaten',
                    show_more: 'Zeig mehr',
                    show_less: 'Zeige weniger',
                    internalNote: 'Note interne',
                },
            },
        },
    }
);

export default customFrenchMessages;
