import * as React from 'react';
import {
    ListItem,
    ListItemSecondaryAction,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Box,
    Tooltip
} from '@mui/material';
import {Link} from 'react-router-dom';
import {useTranslate} from 'react-admin';
import {format} from "date-fns";
import {green, grey, red} from '@mui/material/colors';

export const Marketplace = (props: { marketplace: any }) => {
    const {marketplace} = props;
    const translate = useTranslate();

    const link = `/product-marketplace-allocations?displayedFilters={"isActive"%3Atrue}&filter={"marketplace"%3A${marketplace.id}%2C"isActive"%3Atrue}&order=DESC&page=1&perPage=50&sort=requirementsPercentage`

    return (
        <Tooltip
            title={translate('resources.reports.quickViewReports.stateOfContent.tooltip', {
                smart_count: 1,
                correct: marketplace.correct,
                total: marketplace.totalProducts
            })}
        >
            <ListItem button component={Link} to={link} sx={{
                borderLeftColor: marketplace.correct === marketplace.totalProducts ? green[500] : red[500],
                borderBottomColor: grey['A100'],
                borderLeftWidth: 5,
                borderBottomWidth: 1,
                borderLeftStyle: 'solid',
                borderBottomStyle: 'solid'
            }}>
                <ListItemAvatar>
                    <Avatar src={marketplace.logo} sx={{bgcolor: 'background.paper'}} alt={marketplace.name}/>
                </ListItemAvatar>
                <ListItemText
                    primary={marketplace.name}
                    secondary={translate('resources.reports.quickViewReports.stateOfContent.lastExport', {
                        smart_count: 1,
                        date: marketplace.lastExport === 'n/a' ? 'n/a' : format(new Date(marketplace.lastExport), 'dd.MM.yyyy HH:mm')
                    })}
                />
                <ListItemSecondaryAction>
                    <Box
                        component="span"
                        sx={{
                            marginRight: '1em',
                            color: marketplace.correct === marketplace.totalProducts ? green[900] : red[900]
                        }}
                    >
                        {`${marketplace.correct}/${marketplace.totalProducts}`}
                    </Box>
                </ListItemSecondaryAction>
            </ListItem>
        </Tooltip>
    );
};
