import * as React from 'react';
import {DateInput, SelectInput,} from 'react-admin';
import {STATUSES, TYPES} from './HitchRequestShow';

const hitchRequestsFilters = () => {
    return [
        <SelectInput
            alwaysOn
            source="hitchType"
            choices={TYPES}
        />,
        <SelectInput
            source="status"
            choices={STATUSES}
        />,
        <DateInput
            source="createdAt_gte"
            name="createdAt_gte"
            label={`resources.back_in_stock.requested_since`}
        />,
        <DateInput
            source="createdAt_lte"
            name="createdAt_lte"
            label={`resources.back_in_stock.requested_before`}
        />
    ];
}

export default hitchRequestsFilters;
